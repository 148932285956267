<template>
  <div>
    <b-card>
      <div class="btn1 mb-1">
        <div class="search">
          <b-input-group class="input-merge mt-">
            <b-form-input placeholder="Search" v-model="search" />
          </b-input-group>
          <b-button @click="addUserAction" class="adduser mx-2 px-3"
            >Add User</b-button
          >
        </div>
        <b-sidebar
          id="sidebar-right"
          :title="sidebarAction + ' User Details.'"
          right
          shadow
        >
          <div class="px-3 py-2">
            <template>
              <b-form @submit.prevent>
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      class="nameid"
                      label="First Name"
                      label-for="vi-first-name"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="UserIcon" />
                        </b-input-group-prepend>

                        <b-form-input
                          id="vi-first-name"
                          placeholder="Name"
                          v-model="form.name"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>

                  <!-- Email -->
              
                <b-col cols="12">
                  <b-form-group label="Email" label-for="client-email">
                    <validation-observer ref="emailvalidationref">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="MailIcon" />
                        </b-input-group-prepend>

                        <b-form-input
                          v-model="form.client_email"
                          id="client-email"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Email"
                        />
                        
                      </b-input-group>
                      <small class="text-danger row col-lg-12">{{ errors[0] }}</small>
                    </validation-provider>
                    </validation-observer>
                  </b-form-group>
                  
                </b-col>

                  <b-col cols="12">
                    <b-form-group
                      class="nameid"
                      label="Role"
                      label-for="vi-first-name"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                          id="vi-first-name"
                          placeholder="Role"
                          v-model="form.role"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>

                  <!-- submit and reset -->
                  <b-col class="nameid">
                  
                     <b-col>
                      <div class="custom-control custom-switch mb-1">
                        <input type="checkbox" class="custom-control-input" id="customSwitches">
                        <label class="custom-control-label" for="customSwitches"></label>
                      </div>
                    </b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      @click="saveUser"
                      @click.prevent="validationForm"
                    >
                      Submit
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </template>
          </div>
        </b-sidebar>
      </div>
      <b-table small :fields="fields" :items="items" responsive="sm">
        <template #cell(name)="data">
          <b-avatar
            variant="primary" text="BV"
            src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/4-small.2149d551.png"
          />
          {{ data.value.first + data.value.last }}
        </template>
        <template #cell(Popularity)="data">
          <b-progress
            :value="data.value.value"
            max="100"
            :variant="data.value.variant"
            striped
          />
        </template>
        <template #cell(order_status)="data">
          <b-badge pill :variant="data.value.variant">
            {{ data.value.status }}
          </b-badge>
        </template>

        <template #cell(edit)="data">
          <div class="h2 mb-0">
            <feather-icon
              class="edit"
              icon="EditIcon"
              @click="editUserAction"
            />
          </div>
        </template>
      </b-table>

      <template>
        <div class="demo-spacing-0">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BTable,
  BProgress,
  BBadge,
  BButton,
  BSidebar,
  VBToggle,
  BPagination,
  BSpinner,
  BAvatar
} from "bootstrap-vue";
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BCol,
  BFormSelect,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: {
    BCard,
    required,
    email,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BPagination,
    BRow,
    BCardText,
    BLink,
    BTable,
    BProgress,
    BBadge,
    BTable,
    BButton,
    FeatherIcon,
    BSidebar,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BAvatar,
    BFormSelect,
    BFormFile
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      search: "",

      form: {
        name: "",
        email: "",
        role: "",
      },
      sidebarAction: "Add",
      currentPage: 1,
      perPage: 1,
      rows: 200,
      emailValue: "",
      required,
      email,
      fields: [
        { key: "name", label: "Name" },
        "EMAIL ",
        { key: "order_status", label: "Status" },
        { key: "edit", label: "Action" },
      ],
      items: [
        {
          USER: "@bkrabbe1d",
          name: { first: "Fitbit", last: "Activity Tracker" },
          EMAIL: "bkrabbe1d@home.pl",
          order_status: { status: "Active", variant: "success" },
        },
        {
          USER: "@bkrabbe1d",
          name: { first: "Fitbit ", last: "Flex Wireless Activity" },
          EMAIL: "bkrabbe1d@home.pl",
          order_status: { status: "InActive", variant: "danger" },
        },
        {
          USER: "@bkrabbe1d",
          name: { first: "Fitbit", last: "Sleep Tracker Wristband" },
          EMAIL: "bkrabbe1d@home.pl",
          order_status: { status: "Active", variant: "success" },
        },
        {
          USER: "@bkrabbe1d",
          name: { first: "Fitbit", last: "Sleep Wristband" },
          EMAIL: "bkrabbe1d@home.pl",
          order_status: { status: "InActive", variant: "danger" },
        },
        {
          USER: "@bkrabbe1d",
          name: {
            first: "Fitbit",
            last: "Clip for Zip Wireless Activity Trackers",
          },
          EMAIL: "bkrabbe1d@home.pl",
          order_status: { status: "InActive", variant: "danger" },
        },
      ],
    };
  },
  methods: {
    addUserAction() {
      this.sidebarAction = "Add";
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
    },
    editUserAction() {
      this.sidebarAction = "Edit";
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
    },
    saveUser() {
      console.log(this.form);
    },
    validationForm() {
      this.$refs.emailvalidationref.validate().then((success) => {
        if (success) {
          alert("form submitted!");
        }
      });
    },
  },
  
};
</script>
<style>
.btn1 {
  text-align: end;
}
.nameid {
  text-align: start;
}
.search {
  display: flex;
  width: 70%;
  margin-left: auto;
}
.adduser {
  height: 3rem;
  width: 172px;
}
.input-merge {
  height: 3.5rem;
}
#sidebar-right {
  width: 30%;
}
.edit {
  cursor: pointer;
}
</style>